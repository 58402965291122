import React from "react";
import {medilanceIntro} from "../../images";
import StoreButton from "../container/StoreButton";

const HomeMedilanceIntroM = () => {
    return (
        <div className={ 'p-home-intro bg-gradient-to-b from-white to-[rgb(248_249_250)]' }>
            <div className={ 'flex flex-col items-center' }>
                {/* 앱 이름 및 앱 설명 */}
                <span className={ 'text-16px font-semibold text-[rgb(196,191,197)]' }>Medilance</span>
                <div className={ 'h-[12px]' } />
                <span className={'whitespace-pre-wrap text-22px font-bold text-center'}>
                    {'메딜런스는 과학적인 측정을 통한\n데이터 바탕으로\n'}
                    <span className={ 'text-[rgb(194,51,208)]' }>{'맞춤형 운동법'}</span>
                    <span>{'을 제공합니다.'}</span>
                </span>
                <div className={ 'h-[20px]' } />

                {/*  태그  */}
                <span className={ 'text-14px font-semibold text-[rgb(172,144,175)] text-center' }>
                    { '과학적인 측정 • 분석 데이터 • 찾아가는 서비스 • 맞춤형 운동법' }
                </span>
                <div className={ 'h-[56px]' } />

                {/*  앱 이미지  */}
                <div className={ 'w-full' }>
                    <img src={medilanceIntro} alt={ '메딜런스 인트로 1' } />
                </div>
                <div className={ 'h-[32px]' } />

                {/*  구글 로그인  */}
                <div className={ 'flex flex-row gap-x-[12px] px-[6px]' }>
                    <StoreButton diff={'google'}/>
                    <StoreButton diff={'apple'}/>
                </div>
            </div>
        </div>
    );
}

export default HomeMedilanceIntroM;