import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import Home from "./pages/home/Home";
import HomeRoutes from "./pages/home/HomeRoutes";
import InquiryRoutes from "./pages/inquiry/inquiryIndex";
import MediaRoutes from "./pages/media/MediaRoutes";
import Coop from "./pages/coop/Coop";
import Education from "./pages/education/Education";
import Service from "./pages/service/Service";

class Routes extends Component {
    render() {
        let indexRoute;
        if (isMobile) {
            // indexRoute = <Route exact path='/' component={LandingMobile} />;
        } else {
            indexRoute = <Route exact path='/' component={Home} />;
        }
        return (
            <BrowserRouter>
                <Switch>
                    <Route
                        path="/home"
                        render={(props) => <HomeRoutes {...props} />}
                    />
                    <Route
                        path="/media"
                        render={(props) => <MediaRoutes {...props} />}
                    />
                    <Route
                        path="/coop"
                        render={(props) => <Coop {...props} />}
                    />
                    <Route
                        path="/education"
                        render={(props) => <Education {...props} />}
                    />
                    <Route
                        path="/service"
                        render={(props) => <Service {...props} />}
                    />
                    <Route
                        path="/inquiry"
                        render={(props) => <InquiryRoutes {...props} />}
                    />
                    <Redirect to="/home" />
                    <Redirect from="/" to="/home" />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Routes;
