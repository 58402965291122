import React from 'react';
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import {FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight} from "react-icons/fa";

const PaginationWrapper = (props) => {
  let { isActive, pageNumber, itemPerPage, callback, limit } = props;
  console.log(props)
  let possiblePageMax = 1;
  let pageMin = 1;
  let pageMax = 1;

  let pageNumbers = [];

  possiblePageMax = Math.ceil(pageNumber / limit);
  if (possiblePageMax === 0) possiblePageMax = 1;

  // 페이지 최소 범위
  pageMin = (Math.ceil(isActive / itemPerPage) - 1) * itemPerPage + 1;
  if (pageMin < 1) pageMin = 1;

  // 페이지 최대 범위
  pageMax = pageMin + itemPerPage - 1;
  if (possiblePageMax < pageMax) pageMax = possiblePageMax;
  console.log('11111',pageMin, pageMax)

  // 페이지 엘레먼트
  pageNumbers = [];
  for (let i = pageMin; i <= pageMax; i++) pageNumbers = [...pageNumbers, i];
  console.log('===',pageNumbers, '===')

  const active = (selectedPage) => {
    if (typeof callback == 'function') callback(selectedPage);
    isActive = selectedPage;
  };

  const next = () => {
    let wantToGo = isActive + 1;
    if (wantToGo <= possiblePageMax) {
      isActive = wantToGo;
      if (typeof callback == 'function') callback(isActive);
    }
  };

  const goEnd = () => {
    isActive = possiblePageMax;
    if (typeof callback == 'function') callback(isActive);
  };

  const prev = () => {
    let wantToGo = isActive - 1;
    if (wantToGo < 1) {
      isActive = 1;
    } else {
      isActive = wantToGo;
    }
    if (typeof callback == 'function') callback(isActive);
  };

  const goStart = () => {
    isActive = 1;
    if (typeof callback == 'function') callback(isActive);
  };

  return (
      <nav aria-label="...">
        <Pagination
            style={{ listStyle: 'none', display: 'flex', paddingLeft: '0', borderRadius: '0.375rem', justifyContent: 'center', alignItems: 'center', gap: '6px'}}
        >

          <PaginationItem>
            <PaginationLink
                onClick={prev}
                tabIndex="-1"
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '0',
                  wordWrap: 'break-word',
                  backgroundColor: 'white',
                  backgroundClip: 'border-box',
                  border: '1px solid white',
                  borderRadius: '15%',
                  cursor: 'pointer',
                    color: 'gray',
                  fontSize: '1.5rem'
                }}
            >
              <FaAngleLeft />
            </PaginationLink>
          </PaginationItem>

          {pageNumbers.map((page) => (
              <PaginationItem key={page}>
                <PaginationLink
                    color="primary"
                    onClick={() => active(page)}
                    style={page === isActive?
                        {
                          minWidth: '0',
                          wordWrap: 'break-word',
                          backgroundColor: 'white',
                          backgroundClip: 'border-box',
                          border: '1px solid white',
                          borderRadius: '15%',
                          cursor: 'pointer',
                            color: 'black',
                          fontSize: '1.5rem'
                        }:{
                            minWidth: '0',
                            wordWrap: 'break-word',
                            backgroundColor: 'white',
                            backgroundClip: 'border-box',
                            border: '1px solid white',
                            borderRadius: '15%',
                            cursor: 'pointer',
                            color: 'gray',
                            fontSize: '1.5rem'
                        }}
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
          ))}

          <PaginationItem >
            <PaginationLink
                onClick={next}
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '0',
                  wordWrap: 'break-word',
                  backgroundColor: 'white',
                  backgroundClip: 'border-box',
                  border: '1px solid white',
                  borderRadius: '15%',
                  cursor: 'pointer',
                    color: 'gray',
                  fontSize: '1.5rem'
                }}
            >
              <FaAngleRight />
            </PaginationLink>
          </PaginationItem>

        </Pagination>
      </nav>
  );
};

export default PaginationWrapper;
