import styled from "styled-components";

import {useEffect, useState} from "react";
import "../../font/font.css";
import {useHistory, useLocation} from "react-router-dom";
import {media1, mediaM, testImage} from "../../images";
import {isMobile} from "react-device-detect";
import {mediaData, mediaVideoData} from "./media.data";
import useHistoryState from "../../components/useHistoryState";
import PaginationWrapper from "../../components/pagination";
import {paginate} from "../utils";
import BannerContainerM from "../../components/container/BannerContainerM";
import DefaultLayout from "../../components/DefaultLayout";

const Row = styled.div`
    display: flex;

    .container {
        display: flex;
        flex-wrap: wrap;
    }

    .item {
        flex: 1 1 40%;
    }
`;

function Media() {
    const history = useHistory();
    const [data, setData] = useHistoryState([], 'data');
    const [pageActive, setPageActive] = useHistoryState(1, 'pageActive');
    const [pageCount, setPageCount] = useHistoryState(1, 'pageCount');
    console.log(pageActive, pageCount)

    useEffect(() => {
        setPageCount(mediaData.length);
        setData(paginate(mediaData.sort((a, b) => new Date(b.date) - new Date(a.date)), pageActive, 6));
    }, [pageActive]);

    return (
        <DefaultLayout>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <img className={'hidden md:block'} src={media1} style={{width: '100%', minWidth: '900px'}}/>
                <BannerContainerM title={'미디어룸'} content={'Medilance의 다양한 소식을 전해드립니다.'}/>
            </div>
            <div className={'px-[20px]'}>
                <div className={'py-[32px] 2xl:px-[300px] md:py-[120px] flex flex-col items-center'}>
                    <div className={'w-full'}>
                        <div className={'flex flex-col md:px-0'}>
                            <span className={'text-18px md:text-[40px] font-bold'}>뉴스</span>
                            <div className={'h-[16px] md:h-[30px]'}/>
                            <div className={'flex flex-col w-full xl:grid xl:grid-cols-2 gap-[20px]'}>

                                {data.map((v) => {
                                    return <>
                                        <div
                                            className={'flex flex-row items-center rounded-2xl bg-[rgb(248_248_248)] hover:scale-[1.02] transition duration-300 cursor-pointer select-none'}>
                                            <a href={v.link} target="_blank">
                                                <img
                                                    className={'rounded-l-2xl aspect-square h-[112px] md:w-[198px] md:h-auto object-cover'}
                                                    src={v.thumbnail}/>
                                            </a>
                                            <div className={'flex px-[12px] md:px-[20px] flex-col w-full'}>
                                                <span
                                                    className={'text-12px md:text-[14px] font-medium md:font-semibold text-[rgb(80,85,92)] md:line-clamp-2 line-clamp-1'}>{v.date}
                                                </span>
                                                <div className={'h-[8px]'}/>
                                                <a href={v.link} target="_blank" style={{textDecoration: 'none'}}>
                                                    <span
                                                        className={'text-16px md:text-18px font-bold md:font-semibold line-clamp-2'}>{v.title}
                                                    </span>
                                                </a>
                                                <div className={'h-[8px]'}/>
                                                <span
                                                    className={'md:text-base md:font-normal text-12px font-medium line-clamp-2'}>{v.from}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                })}
                            </div>
                            <div className={'mt-[16px]'}>
                                <PaginationWrapper
                                    isActive={pageActive}
                                    pageNumber={pageCount}
                                    callback={(page) => setPageActive(page)}
                                    itemPerPage={5}
                                    limit={6}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'h-[64px]'}/>
                    <div className={'w-full'}>
                        <span className={'text-18px md:text-[40px] font-bold'}>유튜브</span>
                        <div className={'h-[16px] md:h-[30px]'}/>
                        <div className={'grid grid-cols-2 xl:grid-cols-3 gap-[20px]'}>
                            {mediaVideoData.map((v) => {
                                return <div
                                    className={'flex flex-col hover:scale-[1.02] transition duration-300 cursor-pointer select-none'}>
                                    <a href={v.link} target="_blank">
                                        <img className={'w-full h-[200px] rounded-t-2xl object-cover'} src={v.thumbnail}
                                             alt={'섬네일'}/>
                                    </a>
                                    <div className={'px-4 py-4 h-28 rounded-b-2xl'}
                                         style={{backgroundColor: '#f8f8f8'}}>
                                        <div style={{fontSize: '0.9rem', color: '#646464'}}>
                                            {v.date}
                                        </div>
                                        <a href={v.link} target="_blank" style={{textDecoration: 'none'}}>
                                            <div
                                                className={'text-16px md:text-18px font-bold md:font-semibold line-clamp-2'}>
                                                {v.title}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );

    return (
        <>
            {isMobile
                ?
                <>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <img src={mediaM} style={{width: '100%'}}/>
                    </div>
                    <div style={{width: '100%', textAlign: 'center', paddingTop: '20%'}}>
                        <h1>
                            뉴스
                        </h1>
                    </div>
                    {data.map((v) => {
                        return <>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '90%',
                                margin: '0 auto',
                                padding: '1%'
                            }}>
                                <div style={{display: 'flex', width: '100%', padding: '1%', height: '135px'}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '35%',
                                        overflow: 'hidden'
                                    }}>
                                        <a href={v.link} target="_blank" style={{textDecoration: 'none'}}>
                                            <img src={v.thumbnail}
                                                 style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                                        </a>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '65%',
                                        backgroundColor: '#f8f8f8',
                                        padding: '3%'
                                    }}>
                                        <div style={{fontSize: '0.8rem', color: '#646464'}}>
                                            {v.date}
                                        </div>
                                        <a href={v.link} target="_blank" style={{textDecoration: 'none'}}>
                                            <div style={{
                                                fontSize: '1rem',
                                                color: '#646464',
                                                fontWeight: '700',
                                                margin: '5% 0'
                                            }}>
                                                {v.title}
                                            </div>
                                        </a>
                                        <div style={{fontSize: '0.9rem', color: '#646464', fontWeight: '700'}}>
                                            {v.from}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    })}
                    <br/><br/>
                    <PaginationWrapper
                        isActive={pageActive}
                        pageNumber={pageCount}
                        callback={(page) => setPageActive(page)}
                        itemPerPage={6}
                        limit={6}
                    />
                    <div style={{width: '100%', textAlign: 'center', paddingTop: '20%'}}>
                        <h1>
                            유튜브
                        </h1>
                    </div>
                    {mediaVideoData.map((v) => {
                        return <>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '90%',
                                margin: '0 auto',
                                padding: '1%'
                            }}>
                                <div style={{display: 'flex', width: '100%', padding: '1%', flexDirection: 'column'}}>
                                    <div style={{display: 'flex', flexDirection: 'column', height: '70%'}}>
                                        <a href={v.link} target="_blank">
                                            <img src={v.thumbnail} style={{width: '100%'}}/>
                                        </a>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '30%',
                                        backgroundColor: '#f8f8f8',
                                        padding: '6%'
                                    }}>
                                        <div style={{fontSize: '0.9rem', color: '#646464'}}>
                                            {v.date}
                                        </div>
                                        <div style={{
                                            fontSize: '1.5rem',
                                            color: '#646464',
                                            fontWeight: '700',
                                            margin: '5% 0'
                                        }}>
                                            <a href={v.link} target="_blank" style={{textDecoration: 'none'}}>
                                                {v.title}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    })}
                </>
                :
                <>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <img className={'hidden md:block'} src={media1} style={{width: '100%', minWidth: '900px'}}/>
                        <BannerContainerM title={'미디어룸'} content={'Medilance의 다양한 소식을 전해드립니다.'}/>
                    </div>
                    <span className={'text-18px md:text-[40px] font-bold'}>뉴스</span>
                    {/*<div style={{*/}
                    {/*    display: 'flex',*/}
                    {/*    flexDirection: 'column',*/}
                    {/*    width: '70%',*/}
                    {/*    margin: '0 auto',*/}
                    {/*    paddingTop: '5%'*/}
                    {/*}}>*/}
                    {/*    <h1>*/}
                    {/*        뉴스*/}
                    {/*    </h1>*/}
                    {/*</div>*/}
                    <Row>
                        <div class="container" style={{width: '70%', margin: '0 auto'}}>
                            {data.map((v) => {
                                return <>
                                    <div class="item" style={{
                                        backgroundColor: '#f8f8f8',
                                        margin: '0.5%',
                                        display: 'flex',
                                        maxWidth: '49%'
                                    }}>
                                        <div style={{width: '40%', height: '100%', maxHeight: '300px'}}>
                                            <a href={v.link} target="_blank">
                                                <img src={v.thumbnail} style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    overflow: 'hidden'
                                                }}/>
                                            </a>
                                        </div>
                                        <div style={{
                                            width: '60%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <div style={{
                                                height: '25%',
                                                fontSize: '1rem',
                                                fontWeight: '200',
                                                color: '#646464',
                                                marginLeft: '20px'
                                            }}>
                                                <h3>{v.date}</h3>
                                            </div>
                                            <a href={v.link} target="_blank" style={{textDecoration: 'none'}}>
                                                <div style={{height: '50%', marginLeft: '20px'}}>
                                                    <h2>{v.title}</h2>
                                                </div>
                                            </a>
                                            <div style={{height: '25%', marginLeft: '20px'}}>
                                                <h3>{v.from}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            })}
                        </div>
                    </Row>
                    <br/><br/>
                    <PaginationWrapper
                        isActive={pageActive}
                        pageNumber={pageCount}
                        callback={(page) => setPageActive(page)}
                        itemPerPage={5}
                        limit={6}
                    />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '70%',
                        margin: '0 auto',
                        paddingTop: '5%'
                    }}>
                        <h1>
                            유튜브
                        </h1>
                    </div>
                    <div className={'flex justify-center mb-16'}>
                        <div className={'grid grid-cols-3 gap-x-2 gap-y-2 max-w-[70%]'}>
                            {mediaVideoData.map((v) => {
                                return <>
                                    <div>
                                        <div style={{height: '70%'}}>
                                            <a href={v.link} target="_blank">
                                                <img src={v.thumbnail}
                                                     style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                                            </a>
                                        </div>
                                        <div style={{height: '30%', backgroundColor: '#f8f8f8', padding: '5%'}}>
                                            <div style={{fontSize: '0.9rem', color: '#646464'}}>
                                                {v.date}
                                            </div>
                                            <a href={v.link} target="_blank" style={{textDecoration: 'none'}}>
                                                <div style={{
                                                    fontSize: '1.5rem',
                                                    color: '#646464',
                                                    fontWeight: '700',
                                                    margin: '5% 0'
                                                }}>
                                                    {v.title}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </>
                            })}
                        </div>
                    </div>
                    {/*<div style={{ display: 'flex', flexDirection: 'row', width: '70%', margin: '0 auto', padding: '1%', marginBottom: '5%' }}>*/}
                    {/*    {mediaVideoData.map((v) => {*/}
                    {/*        return <>*/}
                    {/*            <div style={{ display: 'flex', width: '25%', padding: '1%', flexDirection: 'column' }}>*/}
                    {/*                <div style={{ height: '70%' }}>*/}
                    {/*                    <a href={v.link} target="_blank">*/}
                    {/*                        <img src={v.thumbnail} style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>*/}
                    {/*                    </a>*/}
                    {/*                </div>*/}
                    {/*                <div style={{ height: '30%', backgroundColor: '#f8f8f8', padding: '5%'}}>*/}
                    {/*                    <div style={{ fontSize: '0.9rem', color: '#646464' }}>*/}
                    {/*                        {v.date}*/}
                    {/*                    </div>*/}
                    {/*                    <a href={v.link} target="_blank" style={{ textDecoration: 'none' }}>*/}
                    {/*                        <div style={{ fontSize: '1.5rem', color: '#646464', fontWeight: '700', margin: '5% 0' }}>*/}
                    {/*                            {v.title}*/}
                    {/*                        </div>*/}
                    {/*                    </a>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </>*/}
                    {/*    })}*/}
                    {/*</div>*/}
                </>
            }
        </>
    )
}

export default Media;