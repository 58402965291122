import {bannerM, downloadCompany} from "../../images";
import React from "react";

const HomeTopBannerM = ({onDownloadClicked, isDownloading}) => {
    return (
        <div className={'relative flex justify-center items-center'}>
            <img className={'w-full'} src={bannerM} alt={'배너'}/>
            <span className={'absolute text-22px font-bold text-white text-center whitespace-pre-wrap'}>
                        {'메딜런스와 함께 생애전주기를\n더 건강하고 행복하게 만드세요.'}
                    </span>
            <div className={'absolute right-0 bottom-0 cursor-pointer'}
                 onClick={onDownloadClicked}>
                {
                    !isDownloading ?
                        <img className={'w-[150px]'} src={downloadCompany} alt={'회사소개 다운로드'}/>
                        :
                        <div className={ 'w-[150px] pb-4 flex justify-center' }>
                            <span className="loading loading-spinner"></span>
                        </div>
                }
            </div>
        </div>
    );
}

export default HomeTopBannerM;