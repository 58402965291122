import {atom} from "recoil";

export const recoilState = atom({
    key: 'data',
    default: false
});

export const portfolioData = [
    {
        id: 1,
        url: 'https://logd-images.s3.ap-northeast-2.amazonaws.com/%E1%84%88%E1%85%AE%E1%84%85%E1%85%AD+%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6.png'
    }
]

export const title = ['회사정보', '>' ,'프로젝트 정보', '>', '개인정보처리방침']
export const make = ['신규제작', '리뉴얼']
export const type = ['웹사이트 / 모바일 사이트', '모바일 APP' ,'CI/BI', '캐릭터디자인', '일러스트디자인', '편집디자인', '그래픽디자인', '기타']
export const price = ['1,000만원 ~ 2,000만원', '2,000만원 ~ 3,000만원' ,'3,000만원 ~ 5,000만원', '5,000만원 ~ 1억원', '1억원 이상', '직접입력']
export const schedule = ['1~2개월내 오픈', '3개월내 오픈' ,'4~6개월내 오픈', '12개월내 오픈']
export const agreeText = '1. 개인정보의 수집·이용 목적, 수집하는 개인정보의 항목 및 수집방법\n' +
    '가. 컨텐츠 제공, 특정 맞춤 서비스 제공, 본인인증\n' +
    '나. 회원관리\n' +
    '회원제 서비스 제공, 개인 식별, “0000” 이용약관 위반 회원에 대한 이용제한 조치, 서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용 행위 제재, 가입의사 확인, 가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달, 회원탈퇴의사의 확인\n\n' +
    '다. 신규 서비스 개발 및 마케팅·광고에의 활용\n' +
    '신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스 이용에 대한 통계4. 경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)'