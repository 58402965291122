import React from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Media from "./Media";
import Sidebar from "../../components/Sidebar";
import DefaultLayout from "../../components/DefaultLayout";

function MediaRoutes(state) {

    return (
        <DefaultLayout>
            <Media />
        </DefaultLayout>
    ) 
}

export default MediaRoutes;
