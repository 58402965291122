import {IoLogoGooglePlaystore} from "react-icons/io5";
import {IoLogoApple} from "react-icons/io";

const StoreButton = ({diff, androidLink, iosLink}) => {
    return (
        <div
            className={'bg-white text-black rounded-lg drop-shadow shadow-gray-300 px-4 md:px-5 py-3 text-16px md:text-xl flex flex-row items-center gap-x-2 cursor-pointer'}
            onClick={() => diff === 'google' ? window.location.href = androidLink ?? 'https://play.google.com/store/apps/details?id=com.medilance.app&hl=ko' : window.location.href = iosLink ?? 'https://apps.apple.com/kr/app/%EB%A9%94%EB%94%9C%EB%9F%B0%EC%8A%A4/id6478205504'}
        >
            {
                diff === 'google' ?
                    <IoLogoGooglePlaystore className={'text-3xl'}/>
                    : <IoLogoApple className={'text-4xl'}/>
            }
            <span>{diff === 'google' ? 'Google Play' : 'App Store'}</span>
        </div>
    );
}

export default StoreButton;