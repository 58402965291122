import React from 'react';
import Home from "./Home";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import DefaultLayout from "../../components/DefaultLayout";

function HomeRoutes() {
    return(
        <DefaultLayout>
            <Home />
        </DefaultLayout>
    )
}

export default HomeRoutes;
