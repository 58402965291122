export const mediaData = [
    {
        id: 1,
        title: '몸의 건강, 과학이 만든다…메딜런스 화제 집중',
        date: '2021.10.17 17:16',
        from: '스포츠경향',
        thumbnail: 'https://images.khan.co.kr/article/2021/10/17/l_2021101802000821700176881.jpg',
        link: 'https://sports.khan.co.kr/news/sk_index.html?art_id=202110171625003',
    },
    {
        id: 2,
        title: '[사람in] 김수열 더블에프코리아 메딜런스 대표 "운동을 관두지 않으면 몸은 한계가 없다"',
        date: '2021.12.12 16:10',
        from: '중부일보',
        thumbnail: 'https://cdn.joongboo.com/news/photo/202112/2021121201000449200017171.jpg',
        link: 'http://www.joongboo.com/news/articleView.html?idxno=363516334',
    },
    {
        id: 3,
        title: '(사)더블에프 코리아 메딜런스, HJ매그놀리아 국제병원·율치한방병원과 업무협약 체결',
        date: '2022.12.07 16:47',
        from: '매일일보',
        thumbnail: 'https://www.m-i.kr/news/photo/202212/969685_733273_5536.jpg',
        link: 'https://www.m-i.kr/news/articleView.html?idxno=969685',
    },
    {
        id: 4,
        title: '더블에프코리아 메딜런스, HJ매그놀리아 국제병원ㆍ율치한방병원과 업무협약 체결',
        date: '2022-12-06 22:51:41',
        from: '스포츠서울',
        thumbnail: 'https://file.sportsseoul.com/news/legacy/2022/12/06/news/2022120601000342400023811.jpg',
        link: 'https://www.sportsseoul.com/news/read/1184022?ref=naver',
    },
    {
        id: 5,
        title: '[SS인터뷰]김수열 더블에프 메딜런스 대표 "모든 국민의 정신과 몸이 건강할 수 있도록 만들겠다“',
        date: '2022-08-08 10:35:47',
        from: '스포츠서울',
        thumbnail: 'https://file.sportsseoul.com/news/legacy/2022/08/08/news/2022080801000362500024411.jpg',
        link: 'https://www.sportsseoul.com/news/read/1149097?ref=naver',
    },
    {
        id: 6,
        title: '다산신도시 총연합회-(사)더블에프코리아 메딜런스, 스포츠 활성화 업무협약식 체결',
        date: '2022.05.17 07:10',
        from: '열린뉴스통신',
        thumbnail: 'https://cdn.onews.tv/news/photo/202205/125445_146313_2154.jpg',
        link: 'https://www.onews.tv/news/articleView.html?idxno=125445',
    },
    {
        id: 7,
        title: '[SS현장] 부상 방지와 재활에는 신체 밸런스 맛집 \'더블에프코리아메딜런스\'',
        date: '2022-04-28 15:02:53',
        from: '스포츠서울',
        thumbnail: 'https://file.sportsseoul.com/news/legacy/2022/04/28/news/2022042801001516800111068.jpg',
        link: 'https://www.sportsseoul.com/news/read/1119187?ref=naver',
    },
    {
        id: 8,
        title: '경기도체육회, 더블에프코리아 메딜런스와 MOU 체결해 체육 발전 도모',
        date: '2022-03-10 09:45:34',
        from: '스포츠서울',
        thumbnail: 'https://file.sportsseoul.com/news/legacy/2022/03/10/news/2022031001000430200029961.jpg',
        link: 'https://www.sportsseoul.com/news/read/1103775?ref=naver',
    },
    {
        id: 9,
        title: '(사)더블에프코리아 메딜런스, SPS코리아 등 3개 단체와 스포츠 발전 \'맞손\'',
        date: '2022-01-24 10:16',
        from: '경인방송',
        thumbnail: 'https://cdn.news.ifm.kr/news/photo/202201/327487_46885_3735.JPG',
        link: 'http://www.ifm.kr/news/327487',
    },
    {
        id: 10,
        title: '메딜런스, 스포츠문화 활성화와 사회공헌활동 위한 공동사업 추진',
        date: '2022.01.23 18:20',
        from: '스포츠경향',
        thumbnail: 'https://images.khan.co.kr/article/2022/01/23/l_2022012302001217000247171.jpg',
        link: 'https://sports.khan.co.kr/sports/sk_index.html?art_id=202201231820003&sec_id=530101&pt=nv',
    },
    {
        id: 11,
        title: '(사)더블에프코리아 메딜런스, `KBS 스포츠예술과학원 스포츠종합 예술원`등과 협정식 열어',
        date: '2022.01.23 09:22',
        from: '열린뉴스통신',
        thumbnail: 'https://cdn.onews.tv/news/photo/202201/109495_124117_1613.jpg',
        link: 'https://www.onews.tv/news/articleView.html?idxno=109495',
    },
    {
        id: 12,
        title: '몸의 건강, 과학이 만든다…메딜런스 화제 집중',
        date: '2021.10.17 17:16',
        from: '스포츠경향',
        thumbnail: 'https://images.khan.co.kr/article/2021/10/17/l_2021101802000821700176881.jpg',
        link: 'https://sports.khan.co.kr/sports/sk_index.html?art_id=202110171625003&sec_id=530101&pt=nv',
    },
    {
        id: 13,
        title: '(사)더블에프코리아 메딜런스, 취약계층 스포츠 지원 \'함께 나눔 실천\'',
        date: '2021.09.24 17:37',
        from: '매일일보',
        thumbnail: 'https://www.m-i.kr/news/photo/202109/862386_632318_239.jpg',
        link: 'https://www.m-i.kr/news/articleView.html?idxno=862386',
    },
    {
        id: 14,
        title: '(사)더블에프코리아 메딜런스-대한민국 축복봉사단, 취약계층 건강증진 MOU',
        date: '2021.08.23 06:28',
        from: '열린뉴스통신',
        thumbnail: 'https://cdn.onews.tv/news/photo/202108/86709_92482_591.jpg',
        link: 'https://www.onews.tv/news/articleView.html?idxno=86709',
    },
    {
        id: 15,
        title: '국힘 주광덕 남양주시장 후보 "시민 건강증진 특화프로그램 개발"',
        date: '2022-05-11 11:14',
        from: '경인방송',
        thumbnail: 'https://cdn.news.ifm.kr/news/photo/202205/335138_62556_346.jpg',
        link: 'http://www.ifm.kr/news/335138',
    },
    {
        id: 16,
        title: '메딜런스 "개인맞춤형 통합 메디와 헬스케어 플랫폼, 국내 첫 출시"',
        date: '2023-08-18 10:15',
        from: '경인방송',
        thumbnail: 'http://www.ifm.kr/uploads/cache/files/202308/18/thumb-739df45f2bcc870c0f61ff0d052c6542_1024x0.jpg',
        link: 'https://cp.news.search.daum.net/p/136265929',
    },
    {
        id: 17,
        title: '남양주시. 메딜런스 "개인맞춤형 통합 메디/헬스케어 플랫폼...오는 10월 국내 첫 출시"',
        date: '2023-08-18 14:45',
        from: '아시아뉴스통신',
        thumbnail: 'https://www.anewsa.com/news_images/2023/08/18/mark/20230818140527.jpg',
        link: 'https://www.anewsa.com/detail.php?number=2822531',
    },
    {
        id: 18,
        title: '남양주시. 메딜런스 "개인맞춤형 통합 메디/헬스케어 플랫폼...오는 10월 국내 첫 출시"',
        date: '2024-05-29 10:40',
        from: '헬스경향',
        thumbnail: 'https://www.k-health.com/news/photo/202405/72307_79555_378.jpg',
        link: 'https://www.k-health.com/news/articleView.html?idxno=72307',
    },
    {
        id: 19,
        title: '(주)더블에프코리아 메딜런스, 스포츠과학센터…통합 플랫폼 ‘교육 및 자격증’ 부문 런칭"',
        date: '2024-05-29 09:42',
        from: '매일일보',
        thumbnail: 'https://cdn.m-i.kr/news/photo/202405/1124522_892458_1037.jpg',
        link: 'https://www.m-i.kr/news/articleView.html?idxno=1124522',
    },
    {
        id: 20,
        title: '메딜런스, 충남체고에 \'맞춤형 피지컬 트레이닝\' 서비스 실시',
        date: '2024-03-20 09:40',
        from: '마이데일리',
        thumbnail: 'https://mydaily.co.kr/photos/2024/03/20/2024032009340947807_l.png',
        link: 'https://mydaily.co.kr/page/view/2024032009403637395',
    },
    {
        id: 20,
        title: '메딜런스, 충남체고서 찾아가는 서비스 \'맞춤형 피지컬 트레이닝\' 진행',
        date: '2024-03-20 09:31',
        from: '이넷뉴스',
        thumbnail: 'https://cdn.enetnews.co.kr/news/photo/202403/22152_34494_3014.png',
        link: 'https://www.enetnews.co.kr/news/articleView.html?idxno=22152',
    },
    {
        id: 20,
        title: '메딜런스, 충남체고서 ‘맞춤형 피지컬 트레이닝 서비스‘ 제공',
        date: '2024-03-19 17:38',
        from: '헬스경향',
        thumbnail: 'https://www.k-health.com/news/photo/202403/70827_77960_1451.jpg',
        link: 'https://www.k-health.com/news/articleView.html?idxno=70827',
    },
    {
        id: 20,
        title: '메딜런스 ‘찾아가는 서비스’, 한국체육대학교서 첫 실시',
        date: '2024-03-14 15:23',
        from: '스포츠동아',
        thumbnail: 'https://dimg.donga.com/wps/SPORTS/IMAGE/2024/03/14/123972066.4.jpg',
        link: 'https://sports.donga.com/article/all/20240314/123972098/2',
    },
    {
        id: 20,
        title: '메딜런스, 한국체대서 ‘찾아가는 서비스’ 첫 시행',
        date: '2024-03-14 14:53',
        from: 'IT조선',
        thumbnail: 'https://cdn.it.chosun.com/news/photo/202403/2023092111848_376700_453.jpg',
        link: 'https://it.chosun.com/news/articleView.html?idxno=2023092111848',
    },
    {
        id: 20,
        title: '메딜런스, 한국체육대학교 방문 ‘찾아가는 서비스’ 첫 진행',
        date: '2024-03-14 10:16',
        from: '시사매거진',
        thumbnail: 'https://cdn.sisamagazine.co.kr/news/photo/202403/502880_509344_1519.png',
        link: 'https://www.sisamagazine.co.kr/news/articleView.html?idxno=502880',
    },
    {
        id: 20,
        title: '㈜메딜런스, 한체대와 업무협약 체결',
        date: '2024-01-25 13:27',
        from: '경인방송',
        thumbnail: 'https://cdn.news.ifm.kr/news/photo/202401/378657_107419_2340.jpg',
        link: 'https://news.ifm.kr/news/articleView.html?idxno=378657',
    },
    {
        id: 20,
        title: '(주)메딜런스, 한국체육대학교와 업무협약 체결…"올림픽 유망주, 부상예방 및 피지컬 트레이닝 통한 경기능력 향상"',
        date: '2024-01-25 11:36',
        from: '매일일보',
        thumbnail: 'https://cdn.m-i.kr/news/photo/202401/1087583_853651_949.jpg',
        link: 'https://www.m-i.kr/news/articleView.html?idxno=1087583',
    },
    {
        id: 20,
        title: '㈜메딜런스, 육군사관학교 체육학처 교수진·샌드허스트 출전 생도 교관 초청 방문행사 진행',
        date: '2024-01-24 09:20',
        from: '경인방송',
        thumbnail: 'https://cdn.news.ifm.kr/news/photo/202401/378510_107359_2249.jpg',
        link: 'https://news.ifm.kr/news/articleView.html?idxno=378510',
    },
    {
        id: 20,
        title: '(주)메딜런스, 육군사관학교 체육학처 및 샌드허스트 출전 생도 교관초청 방문행사 진행',
        date: '2024-01-23 18:16',
        from: '매일일보',
        thumbnail: 'https://cdn.m-i.kr/news/photo/202401/1087031_853078_271.jpg',
        link: 'https://www.m-i.kr/news/articleView.html?idxno=1087031',
    },
    {
        id: 20,
        title: '스마트 헬스케어 전문기업, 최적화된 맞춤형 운동 솔루션 프로그램 개발 보급',
        date: '2023-12-27 16:50',
        from: '스포츠서울',
        thumbnail: 'https://file.sportsseoul.com/news/cms/2023/12/27/news-p.v1.20231227.ca5288452a29437abce368ffdfe16b8e_P2.jpg',
        link: 'https://www.sportsseoul.com/news/read/1384399?ref=naver',
    },
].reverse();

export const mediaVideoData = [
    {
        id: 1,
        title: '메딜런스소개',
        date: '2019.11.21',
        thumbnail: 'https://i.ytimg.com/vi/JQkI6T2gKxw/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBxe5jDcD8Q9QPIgXchwM88X_9RfA',
        link: 'https://www.youtube.com/watch?v=JQkI6T2gKxw',
    },
    {
        id: 2,
        title: '[필살기UP] 100세 시대 건강한 몸관리...몸의 비대칭을 잡으세요!',
        date: '2022.04.08',
        thumbnail: 'https://i.ytimg.com/vi/1sOuz0E4N3k/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC7vwZQcH_ZLyyySuAm4XaPqY6J0g',
        link: 'https://www.youtube.com/watch?v=1sOuz0E4N3k',
    },
    {
        id: 3,
        title: '메딜런스',
        date: '2023.11.22',
        thumbnail: 'https://i.ytimg.com/vi/_pHVqKq8AgA/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC7vwZQcH_ZLyyySuAm4XaPqY6J0g',
        link: 'https://www.youtube.com/watch?v=_pHVqKq8AgA',
    },
    {
        id: 1,
        title: '메딜런스 수원',
        date: '2019.11.21',
        thumbnail: 'https://i.ytimg.com/vi/V9_uLdDhQTs/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBxe5jDcD8Q9QPIgXchwM88X_9RfA',
        link: 'https://www.youtube.com/watch?v=V9_uLdDhQTs',
    },
    {
        id: 1,
        title: '메딜런스 충남 체육고등학교',
        date: '2024.03.22',
        thumbnail: 'https://i.ytimg.com/vi/XnwREZ9W5Y4/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBxe5jDcD8Q9QPIgXchwM88X_9RfA',
        link: 'https://www.youtube.com/watch?v=XnwREZ9W5Y4',
    },
    {
        id: 0,
        title: '메딜런스 육군사관학교',
        date: '2024.03.21',
        thumbnail: 'https://i.ytimg.com/vi/KHfNkUl9tic/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC7vwZQcH_ZLyyySuAm4XaPqY6J0g',
        link: 'https://www.youtube.com/watch?v=KHfNkUl9tic',
    },
].reverse();