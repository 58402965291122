import DefaultLayout from "../../components/DefaultLayout";
import {blog, home, instagram, logo, main4} from '../../images';
import Dialog from "../../components/Dialog";
import {useEffect, useState} from "react";
import {cooperation} from "../../data";
import BannerContainerM from "../../components/container/BannerContainerM";
import {clsx} from "clsx";

/* 각 협력센터 컨테이너 */
const CoopContainer = ({eachCooperation, onClick}) => {
    return <div
        className={'flex flex-row rounded-2xl bg-[rgb(248_248_248)] md:w-[650px] hover:scale-[1.02] transition duration-300 cursor-pointer select-none'}
        onClick={() => {
            onClick();
            document.getElementById('coop_modal').showModal();
            /* 모달창이 열릴 때, 모달창 열림 State 를 등록한다. (추후 모달창 닫기 동작 시, 해당 State 는 Pop 된다. Dialog.js 참고) */
            // eslint-disable-next-line no-restricted-globals
            history.pushState({page:"modal"}, document.title);
        }}>
        <div className={'h-[112px] aspect-square md:h-auto md:w-1/3'}>
            {
                eachCooperation['images'].length > 0 ?
                    <img className={'rounded-l-2xl w-full h-full object-cover'}
                         src={eachCooperation['images'][0]}
                         alt={'센터사진'}/>
                    :
                    <div className={'rounded-l-2xl bg-[rgb(30_33_38)] h-full w-full flex justify-center items-center'}>
                        <img className={'rounded-l-2xl w-36 object-cover'}
                             src={logo}
                             alt={'센터사진'}/>
                    </div>
            }
        </div>
        <div className={'flex flex-col justify-center px-[12px] md:px-0 md:pt-[30px] md:pl-[30px] md:pr-[20px] md:pb-[42px] w-2/3'}>
            <span className={'text-16px md:text-[30px] font-bold md:font-semibold md:line-clamp-2 line-clamp-1'}>{eachCooperation['title']}</span>
            <div className={'h-[10px]'}/>
            <span className={'line-clamp-2 md:text-base text-12px md:font-normal font-medium'}>{eachCooperation['content']}</span>
            <div className={'hidden md:flex justify-end gap-x-[10px] h-[14px]'}>
                {
                    !!eachCooperation['blog']
                    && <img className={'w-[30px] h-[30px] cursor-pointer hover:-translate-y-1 transition duration-150'}
                            src={blog} alt={'블로그'} onClick={(event) => {
                        event.stopPropagation();
                        window.open(eachCooperation['blog'], '_blank');
                    }}/>
                }
                {
                    !!eachCooperation['instagram'] &&
                    <img
                        className={'w-[30px] h-[30px] rounded-full cursor-pointer hover:-translate-y-1 transition duration-150'}
                        src={instagram} alt={'인스타그램'} onClick={(event) => {
                        event.stopPropagation();
                        window.open(eachCooperation['instagram'], '_blank');
                    }}/>
                }
                {
                    !!eachCooperation['home'] &&
                    <img className={'w-[30px] h-[30px] cursor-pointer hover:-translate-y-1 transition duration-150'}
                         src={home} alt={'홈'} onClick={event => {
                        event.stopPropagation();
                        window.open(eachCooperation['home'], '_blank');
                    }}/>
                }
            </div>
        </div>
    </div>
}

/* 협력센터 모달 내 정보 Row */
const CoopModalInfo = ({className, title, content}) => {
    return <div className={clsx('flex w-full', [className])}>
        <span className={'w-24 text-14px md:text-[20px] font-bold md:font-semibold'}>{title}</span>
        <span className={'w-full text-14px md:text-[20px] text-[rgb(80,85,92)] font-medium md:font-light whitespace-pre-wrap'}>{content}</span>
    </div>
}

const Coop = () => {
    const [selectedCoop, setSelectedCoop] = useState();

    return <DefaultLayout>
        <Dialog modalId={'coop_modal'} showClose={true} showCarousel={true}
                carouselImages={!!selectedCoop ? selectedCoop['images'] : []}>
            {
                selectedCoop &&
                <div
                    className={'relative flex flex-col mt-[24px] px-[20px] md:mt-0 md:pt-[40px] md:pb-[30px] md:pl-[50px] md:pr-[40px]'}>
                    <span className={'text-18px md:text-[30px] font-bold'}>{selectedCoop['title']}</span>
                    <div className={'md:h-[30px] md:my-0 mt-[12px] mb-[16px]'}>
                        <span className={'md:hidden block text-14px font-medium text-[rgb(80,85,92)]'}>
                            {
                                selectedCoop['content']
                            }
                        </span>
                    </div>
                    <div className={'flex flex-col gap-y-2 md:mb-[14px] mb-[24px]'}>
                        <CoopModalInfo className={'hidden md:flex'} title={'정보'} content={selectedCoop['content']}/>
                        <CoopModalInfo title={'휴무일'} content={selectedCoop['holiday'] ?? '-'}/>
                        <CoopModalInfo title={'영업시간'} content={selectedCoop['businessHours'] ?? '-'}/>
                        <CoopModalInfo title={'지하철'} content={selectedCoop['subway'] ?? '-'}/>
                    </div>
                    {/* 웹 환경 홈페이지 및 전화걸기 레이아웃 */}
                    <div className={'hidden md:block'}>
                        <div className={'flex justify-end gap-x-[10px]'}>
                            {
                                !!selectedCoop['blog']
                                && <img
                                    className={'w-[30px] h-[30px] cursor-pointer hover:-translate-y-1 transition duration-150'}
                                    src={blog} alt={'블로그'} onClick={(event) => {
                                    event.stopPropagation();
                                    window.open(selectedCoop['blog'], '_blank');
                                }}/>
                            }
                            {
                                !!selectedCoop['instagram'] &&
                                <img
                                    className={'w-[30px] h-[30px] rounded-full cursor-pointer hover:-translate-y-1 transition duration-150'}
                                    src={instagram} alt={'인스타그램'} onClick={(event) => {
                                    event.stopPropagation();
                                    window.open(selectedCoop['instagram'], '_blank');
                                }}/>
                            }
                            {
                                !!selectedCoop['home'] &&
                                <img
                                    className={'w-[30px] h-[30px] cursor-pointer hover:-translate-y-1 transition duration-150'}
                                    src={home} alt={'홈'} onClick={event => {
                                    event.stopPropagation();
                                    window.open(selectedCoop['home'], '_blank');
                                }}/>
                            }
                        </div>
                        <div className={'h-[80px]'}/>
                        <div className={'flex justify-end'}>
                            <button type={'button'}
                                    className={'bg-[rgb(30_33_38)] text-[24px] px-[54px] py-[26px] text-white rounded-xl hover:bg-gray-700 transition duration-150 select-none'}
                                    onClick={() => {
                                        window.location.href = `tel:${selectedCoop['tel']}`;
                                    }}>
                                전화걸기
                            </button>
                        </div>
                    </div>
                    {/*  모바일 환경 홈페이지 및 전화걸기 레이아웃  */}
                    <div className={'block md:hidden mb-[26px]'}>
                        <div className={'flex justify-between items-center h-[52px]'}>
                            <div className={'flex gap-x-[10px] w-1/2 md:w-auto'}>
                                {
                                    !!selectedCoop['blog']
                                    && <img
                                        className={'h-[36px] cursor-pointer hover:-translate-y-1 transition duration-150'}
                                        src={blog} alt={'블로그'} onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(selectedCoop['blog'], '_blank');
                                    }}/>
                                }
                                {
                                    !!selectedCoop['instagram'] &&
                                    <img
                                        className={'h-[36px] rounded-full cursor-pointer hover:-translate-y-1 transition duration-150'}
                                        src={instagram} alt={'인스타그램'} onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(selectedCoop['instagram'], '_blank');
                                    }}/>
                                }
                                {
                                    !!selectedCoop['home'] &&
                                    <img
                                        className={'w-auto h-[36px] cursor-pointer hover:-translate-y-1 transition duration-150'}
                                        src={home} alt={'홈'} onClick={event => {
                                        event.stopPropagation();
                                        window.open(selectedCoop['home'], '_blank');
                                    }}/>
                                }
                            </div>
                            <button type={'button'}
                                    className={'bg-[rgb(30_33_38)] w-1/2 md:w-auto h-full md:h-auto md:text-[24px] md:px-[54px] md:py-[26px] text-white rounded-xl hover:bg-gray-700 transition duration-150 select-none'}
                                    onClick={() => {
                                        window.location.href = `tel:${selectedCoop['tel']}`;
                                    }}>
                                전화걸기
                            </button>
                        </div>
                    </div>
                </div>
            }
        </Dialog>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {/* 데스크탑 환경 */}
            <img className={'hidden md:block'} src={main4} alt={'배너'} style={{width: '100%', minWidth: '900px'}}/>
            {/*  모바일 환경  */}
            <BannerContainerM title={'협력센터'} content={'메딜런스의 협력센터들을 만나보세요.'}/>
        </div>
        <div className={'py-[32px] md:py-[120px] flex justify-center'}>
            <div className={'flex flex-col md:px-0 px-[20px]'}>
                <span className={'text-18px md:text-[40px] font-bold'}>협력센터</span>
                <div className={'h-[16px] md:h-[30px]'}/>
                <div className={'flex flex-col xl:grid xl:grid-cols-2 gap-[20px]'}>
                    {
                        cooperation.map(element => {
                            return <CoopContainer
                                eachCooperation={element}

                                onClick={() => {
                                    setSelectedCoop(element);
                                }}/>;
                        })
                    }
                </div>
            </div>
        </div>
    </DefaultLayout>
}

export default Coop;