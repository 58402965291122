import {useHistory} from "react-router-dom";
import {useRecoilState} from "recoil";
import {recoilState} from "../pages/data/data";
import {cancelButton, logo} from "../images";
import styled from "styled-components";
import {FC} from "react";
import {ReactNode} from "react";

const HeaderLineM = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #EBEBEB;
  position: absolute;
  z-index: 95;
`;

function Sidebar() {
    const history = useHistory();
    const [isMenu, setIsMenu] = useRecoilState(recoilState)
    const isIntro = history.location.pathname === '/home'
    const isPort = history.location.pathname === '/media'
    const isCon = history.location.pathname === '/inquiry'
    const isCoop = history.location.pathname === '/coop'
    const isEducation = history.location.pathname === '/education'
    const isService = history.location.pathname === '/service'

    return (
        <>
            {isMenu
                ?
                <>
                    <div style={{ width: '100%', height: '100%', display: 'flex', zIndex: '10000', position: 'fixed' }}>
                        <div className={ 'm-auto w-full py-2 flex absolute md:hidden z-[95] bg-[#1e2126]' }>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', margin: '0 auto' }}>
                                <div>
                                    <img src={logo} style={{ width: '30%', cursor: 'pointer', padding: '3% 0 2% 0' }} onClick={(e) => history.push('/home')}/>
                                </div>
                                <div style={{ textAlign: "right", display: 'flex' }}>
                                    <img src={cancelButton} style={{ width: '90%', cursor: 'pointer', padding: '3% 0 2% 0' }} onClick={(e) => setIsMenu(false)}/>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#1e2126', width: '100%', height: '100%' }}>
                            <div className={ 'flex flex-col gap-y-2' } style={{ padding: '15% 0 0 5%' }}>
                                <SideBarMenu
                                    menuName={ '회사소개' }
                                    isActive={ isIntro }
                                    route={ '/home' }
                                    setIsMenu={setIsMenu}
                                />
                                <SideBarMenu
                                    menuName={ '미디어룸' }
                                    isActive={ isPort }
                                    route={ '/media' }
                                    setIsMenu={setIsMenu}
                                />
                                <SideBarMenu
                                    menuName={ '협력센터' }
                                    isActive={ isCoop }
                                    route={ '/coop' }
                                    setIsMenu={setIsMenu}
                                />
                                <SideBarMenu
                                    menuName={ '교육 및 자격증' }
                                    isActive={ isEducation }
                                    route={ '/education' }
                                    setIsMenu={setIsMenu}
                                />
                                <SideBarMenu
                                    menuName={ '찾아가는 서비스' }
                                    isActive={ isService }
                                    route={ '/service' }
                                    setIsMenu={setIsMenu}
                                />
                                <SideBarMenu
                                    menuName={ '문의' }
                                    isActive={ isCon }
                                    route={ '/inquiry' }
                                    setIsMenu={setIsMenu}
                                />
                            </div>
                        </div>
                    </div>
                </>
                :
                <>

                </>
            }
        </>
    )
}

// 사이드바 메뉴
const SideBarMenu = ({ menuName, isActive, setIsMenu, route }) => {
    const history = useHistory();

    return (
        <h1
            style={isActive ? {color: 'white', fontSize: '1rem', cursor: 'pointer'} : {
                fontSize: '1rem',
                cursor: 'pointer',
                color: 'gray'
            }}
            onClick={(e) => {
                setIsMenu(false)
                history.push(route)
            }}
        >
            { menuName }
        </h1>
    );
}

export default Sidebar;