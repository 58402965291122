import React from "react";
import Inquiry from "./Inquiry";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import DefaultLayout from "../../components/DefaultLayout";

function InquiryRoutes() {

    return(
        <DefaultLayout>
            <Inquiry />
        </DefaultLayout>
    )
}

export default InquiryRoutes