import styled from "styled-components";
import React, {useRef, useState} from "react";
import emailjs from 'emailjs-com';
import AWS from 'aws-sdk';
import {useHistory} from "react-router-dom";
import {inquiry1, inquiryM, successLogo} from "../../images";
import {isMobile} from "react-device-detect";

export const UnderLine = styled.input`
  font-family: regular;
    width: 100%;
    height: 50px;
    border: none;
    background-color: #FAFAFA;
    font-size: 1.3rem;
`;

const TextArea = styled.textarea`
  font-family: regular;
  width: 100%;
  height: 300px;
  border: none;
  background-color: #FAFAFA;
  font-size: 1.3rem;
  resize: none;
`;

const AgreeArea = styled.textarea`
  font-family: regular;
  width: 100%;
  height: 200px;
  border: none;
  background-color: white;
  font-size: 1.2rem;
  resize: none;
`;

const FooterImage = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  padding: 4% 0 0 51%;
  
  img {
    width: 15%;
    cursor: pointer;
  }
`;

const FooterImageOne = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  padding: 4% 0 0 57%;
  
  img {
    width: 21%;
    cursor: pointer;
  }
`;

function Inquiry() {
    const history = useHistory();
    const [isAlert, setIsAlert] = React.useState(false);
    const [check, setCheck] = useState(true);
    const [makeCheck, setMakeCheck] = useState('');
    const [tel, setTel] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contents, setContents] = useState('');
    const form = useRef();
    const fileRef = useRef();
    console.log(tel,email)

     function onFileUpload() {
        const ACCESS_KEY = 'AKIA6DSQW7UHJIMNOQGQ';
        const SECRET_ACCESS_KEY = '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';
        const REGION = "us-east-1";
        const S3_BUCKET = 'rightcode-files';

        // AWS ACCESS KEY를 세팅합니다.
        AWS.config.update({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY
        });

        // 버킷에 맞는 이름과 리전을 설정합니다.
        const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET},
            region: REGION,
        });

        // 파일과 파일이름을 넘겨주면 됩니다.
        // const params = {
        //     ACL: 'public-read',
        //     Body: file,
        //     Bucket: S3_BUCKET,
        //     Key: file.name
        // };

        // myBucket.putObject(params)
        //     .on('httpUploadProgress', (evt) => {
        //         // alert("SUCCESS")
        //     })
        //     .send((err) => {
        //         if (err) console.log(err)
        //     })
    }

    const handleFileOnChange = (event) => {
        //파일 불러오기
        event.preventDefault();
        let file = event.target.files[0];
        // setFile(file);
        if(file) {
            alert('파일이 첨부되었습니다.')
        }
    };

    const handleFileButtonClick = (e) => {
        //버튼 대신 클릭하기
        e.preventDefault();
        fileRef.current.click(); // file 불러오는 버튼을 대신 클릭함
    };

    const sendEmail = (e) => {
        if (isMobile) {
           window.scroll({
               top: 0,
               behavior: 'smooth'
           })
        }
        if(!tel || !name ||! email || !contents){
            return alert('모든 정보를 입력해주세요.')
        }
        if (check === true) {

            emailjs.send(
                'medilance',
                'template_ew5i0nx',
                form.current,
                '2PKXd3vUPIWP02V9D'
            )
                .then((result) => {
                    setIsAlert(true);
                    // history.push('/home');
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

        } else {
            return alert('개인정보보호정책에 동의해주세요.');
        }
    };

    const successAlert = () => {
        setIsAlert(true);
    };

    return (
        <>
            {isMobile
            ?
                <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <img src={inquiryM} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '80%', backgroundColor: '#f8f8f8', padding: '5%', margin: '10% auto' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '100%', borderBottom: '1px solid #2E2E2E' }}>
                                <h3>연락처</h3>
                                <UnderLine
                                    placeholder="연락처를 입력해주세요."
                                    type="text"
                                    name="user_tel"
                                    value={tel}
                                    onChange={(e) => setTel(e.target.value)}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5%' }}>
                            <div style={{ width: '100%', borderBottom: '1px solid #2E2E2E' }}>
                                <h3>단체명</h3>
                                <UnderLine
                                    placeholder="단체명을 입력해주세요."
                                    type="text"
                                    name="user_name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5%' }}>
                            <div style={{ width: '100%', borderBottom: '1px solid #2E2E2E' }}>
                                <h3>이메일</h3>
                                <UnderLine
                                    placeholder="이메일을 입력해주세요."
                                    type="text"
                                    name="user_email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5%' }}>
                            <div style={{ width: '100%', borderBottom: '1px solid #2E2E2E' }}>
                                <h3>문의내용</h3>
                                <TextArea
                                    placeholder="문의내용을 입력해주세요."
                                    type="text"
                                    name="message"
                                    value={contents}
                                    onChange={(e) => setContents(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '5%', marginBottom: '10%' }}>
                        {!tel || !name || !email || !contents
                        ?
                            <div
                                style={{ width: '85%', backgroundColor: '#bfbfbf', padding: '6% 5%', textAlign: 'center', color: 'white', fontSize: '1.2rem', borderRadius: '5px', margin: '0 auto' }}
                                onClick={sendEmail}
                            >
                                등록하기
                            </div>
                        :
                            <div
                                style={{ width: '85%', backgroundColor: '#1e2126', padding: '6% 5%', textAlign: 'center', color: 'white', fontSize: '1.2rem', borderRadius: '5px', margin: '0 auto', cursor: 'pointer' }}
                                onClick={sendEmail}
                            >
                                등록하기
                            </div>
                        }
                    </div>
                    {isAlert &&
                        <div style={{ padding: '3%', width: '75%', position: 'fixed', top: '40%', left: '20%', boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)', backgroundColor: 'white', marginLeft: '-10%', marginTop: '-150px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '3%' }}>
                                <img src={successLogo} style={{ width: '30%', cursor: 'pointer', margin: '0 auto' }} onClick={(e) => setMakeCheck('')}/>
                            </div>
                            <div style={{ textAlign: 'center', margin: '0 auto' }}>
                                <h1>문의 등록 완료</h1>
                                <p>문의 등록이 완료되었습니다.<br/>빠른 시일 내에 답변 드리겠습니다.</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div
                                    style={{ padding: '3% 19%', backgroundColor: '#1e2126', color: 'white', margin: '5% auto', cursor: 'pointer', borderRadius: '5px' }}
                                    onClick={(e) => history.push('/home')}
                                >
                                    홈으로 이동하기
                                </div>
                            </div>
                        </div>
                    }
                </>
            :
                <>
                    <div style={{ width: '65%', paddingTop: '10%', position: 'absolute', top: '25%', left: '50%', transform: 'translate(-50%, 0%)' }}>
                        <>
                            <div style={{ backgroundColor: '#FAFAFA', padding: '5%', marginBottom: '10%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '100%', borderBottom: '1px solid #2E2E2E' }}>
                                        <h3>연락처</h3>
                                        <UnderLine
                                            placeholder="연락처를 입력해주세요."
                                            type="text"
                                            name="user_tel"
                                            value={tel}
                                            onChange={(e) => setTel(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5%' }}>
                                    <div style={{ width: '100%', borderBottom: '1px solid #2E2E2E' }}>
                                        <h3>단체명</h3>
                                        <UnderLine
                                            placeholder="단체명을 입력해주세요."
                                            type="text"
                                            name="user_name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5%' }}>
                                    <div style={{ width: '100%', borderBottom: '1px solid #2E2E2E' }}>
                                        <h3>이메일</h3>
                                        <UnderLine
                                            placeholder="이메일을 입력해주세요."
                                            type="text"
                                            name="user_email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5%' }}>
                                    <div style={{ width: '100%', borderBottom: '1px solid #2E2E2E' }}>
                                        <h3>문의내용</h3>
                                        <TextArea
                                            placeholder="문의내용을 입력해주세요."
                                            type="text"
                                            name="message"
                                            value={contents}
                                            onChange={(e) => setContents(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <img src={inquiry1} style={{ width: '100%', minWidth: '900px' }}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '1100px', padding: '5%' }}>
                        {!tel || !name || !email || !contents
                            ?
                                <div
                                    style={{ backgroundColor: '#bfbfbf', padding: '2% 5%', textAlign: 'center', color: 'white', fontSize: '1.2rem', borderRadius: '5px', margin: '0 auto', marginTop: 'auto', cursor: 'pointer' }}
                                    onClick={sendEmail}
                                >
                                    등록하기
                                </div>
                            :
                                <div
                                    style={{ backgroundColor: '#1e2126', padding: '2% 5%', textAlign: 'center', color: 'white', fontSize: '1.2rem', borderRadius: '5px', margin: '0 auto', marginTop: 'auto', cursor: 'pointer' }}
                                    onClick={sendEmail}
                                >
                                    등록하기
                                </div>
                        }
                    </div>
                    {isAlert &&
                        <div style={{ padding: '3%', width: '25%', position: 'fixed', top: '40%', left: '45%', boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)', backgroundColor: 'white', marginLeft: '-10%', marginTop: '-150px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '3%' }}>
                                <img src={successLogo} style={{ width: '30%', cursor: 'pointer', margin: '0 auto' }} onClick={(e) => setMakeCheck('')}/>
                            </div>
                            <div style={{ textAlign: 'center', margin: '0 auto' }}>
                                <h1>문의 등록 완료</h1>
                                <p>문의 등록이 완료되었습니다.<br/>빠른 시일 내에 답변 드리겠습니다.</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                               <div
                                   style={{ padding: '3% 19%', backgroundColor: '#1e2126', color: 'white', margin: '5% auto', cursor: 'pointer', borderRadius: '5px' }}
                                   onClick={(e) => history.push('/home')}
                               >
                                   홈으로 이동하기
                               </div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default Inquiry;