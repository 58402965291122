import DefaultLayout from "../../components/DefaultLayout";
import {blog, home, main4, main5, sampleLecturer} from "../../images";
import {useState} from "react";
import Dialog from "../../components/Dialog";
import {useQuery} from "@tanstack/react-query";
import EducationApi from "../../api/education/education-api";
import BannerContainerM from "../../components/container/BannerContainerM";

/* 각 강사 컨테이너 */
const EducationContainer = ({category, title, content, thumbnail, onClick}) => {
    return <div
        className={'flex flex-row items-center rounded-2xl bg-[rgb(248_248_248)] md:w-[650px] hover:scale-[1.02] transition duration-300 cursor-pointer select-none'}
        onClick={() => {
            onClick();
            document.getElementById('edu_modal').showModal();
            // eslint-disable-next-line no-restricted-globals
            history.pushState({page:"modal"}, document.title);
        }}>
        <img className={'rounded-l-2xl aspect-square h-[112px] md:w-[198px] md:h-auto object-cover'}
             src={thumbnail}
             alt={'강사 썸네일'}/>
        <div className={'flex px-[12px] md:px-0 flex-col md:pt-[30px] md:pl-[30px] md:pr-[20px] md:pb-[50px] w-full'}>
            <span
                className={'text-12px md:text-[14px] font-medium md:font-semibold text-[rgb(80,85,92)] md:line-clamp-2 line-clamp-1'}>{category}</span>
            <div className={'h-[8px] md:h-[20px]'}/>
            <div className={'flex flex-row gap-x-[4px] md:gap-x-2 items-center'}>
                <span className={'text-16px md:text-[30px] font-bold md:font-semibold'}>{title}</span>
                <span className={'text-12px md:text-[20px] text-[rgb(80,85,92)] font-medium'}> 강사님</span>
            </div>
            <div className={'h-[4px] md:h-[10px]'}/>
            <span className={'md:text-base md:font-normal text-12px font-medium line-clamp-2'}>{content}</span>
        </div>
    </div>
}

/* 각 강사 모달 내 정보 Row */
const EducationModalInfo = ({title, content}) => {
    return <div className={'flex flex-col w-full'}>
        <span className={'w-24 text-14px md:text-[20px] font-bold md:font-semibold mb-2'}>{title}</span>
        <span className={'ml-1 w-full text-14px md:text-[20px] font-medium md:font-light whitespace-pre-wrap'}>{content}</span>
    </div>
}

const Education = () => {
    const [selectedLecturer, setSelectedLecturer] = useState(null);

    const {data, isLoading} = useQuery({
        queryKey: ['educationList'],
        queryFn: EducationApi.list,
    });

    return (
        <DefaultLayout>
            <Dialog modalId={'edu_modal'} showClose={true} showCarousel={true}
                    carouselImages={[selectedLecturer && selectedLecturer['thumbnail']]} boxFit={'contain'}>
                {
                    !!data && selectedLecturer &&
                    <div className={'relative flex flex-col mt-[20px] mb-[26px] px-[20px] md:pt-[30px] md:pb-[60px] md:px-[50px]'}>
                        <span className={'text-14px md:text-[16px] font-medium'}>{selectedLecturer['category']}</span>
                        <div className={'h-[12px] md:h-[10px]'}/>
                        <span className={'text-18px md:text-[30px] font-bold'}>
                            {selectedLecturer['name']}
                            <span className={ 'text-12px font-medium text-[rgb(80,85,92)]' }> 강사님</span>
                        </span>
                        <div className={'h-[6px] md:h-[10px]'}/>
                        <span className={'text-14px md:text-[20px] font-medium md:font-light'}>{selectedLecturer['intro']}</span>
                        <div className={'h-[16px] md:h-[20px]'}/>
                        {
                            !!selectedLecturer['video'] &&
                            <>
                                <video className={'rounded-xl'} src={selectedLecturer['video']} controls/>
                                <div className={'h-[40px] md:h-8'}/>
                            </>
                        }
                        <EducationModalInfo title={'소개'} content={selectedLecturer['content']}/>
                        <div className={'h-[24px] md:h-8'}/>
                        <EducationModalInfo title={'경력'}
                                            content={selectedLecturer['careers'].map(element => `· ${element}\n`)}/>
                    </div>
                }
            </Dialog>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                {/* 데스크탑 환경 */}
                <img className={'hidden md:block'} src={main5} alt={'배너'} style={{width: '100%', minWidth: '900px'}}/>
                {/*  모바일 환경  */}
                <BannerContainerM title={'교육 및 자격증'} content={'메딜런스 교육 프로그램을 통해 여러분의 건강지식을 높여보세요.'}/>
            </div>
            <div className={'py-[32px] md:py-[120px] flex justify-center'}>
                <div className={'flex flex-col md:px-0 px-[20px]'}>
                    <span className={'text-18px md:text-[40px] font-bold'}>교육 및 자격증</span>
                    <div className={'h-[16px] md:h-[30px]'}/>
                    <div className={'flex flex-col xl:grid xl:grid-cols-2 gap-[20px]'}>
                        {
                            !!data &&
                            data['data'].map(element => <EducationContainer category={element['category']}
                                                                            title={element['name']}
                                                                            content={element['intro']}
                                                                            onClick={() => {
                                                                                setSelectedLecturer(element);
                                                                            }}
                                                                            thumbnail={element['thumbnail']}
                            />)
                        }
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default Education;