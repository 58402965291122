import {arrowLeftCircled, arrowRightCircled, close, closeCircled} from "../images";
import {useEffect, useState} from "react";
import {clsx} from "clsx";
import {IoCloseCircle} from "react-icons/io5";
import {debounce, throttle} from "lodash";

/* 원인은 모르지만 throttle 로 처리 시 Threshold delayed time 만큼 연속하여 동작이 일어나서 Debounce 로 처리함. */
const escDebounce = debounce(() => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
}, 100);

const keyUpEvent = (e) => {
    if (e.key === 'Escape') {
        escDebounce();
    }
}

const Dialog = ({modalId, showClose, showCarousel, carouselImages, boxFit = 'cover', children}) => {
    if (!showCarousel && carouselImages.length > 0) {
        throw Error('carouselImage 를 사용하려면 showCarousel 을 활성화해야합니다.');
    }

    /*
    * 페이지 마운트 시, ESC 키 눌림을 감지하여 history 를 pop 하는 동작을 수행한다,
    * ESC 를 통해 모달창을 닫는 이벤트는 캐치할 수 없기 때문에 하기처럼 별도로 구현한다.
    **/
    useEffect(() => {
        window.addEventListener('keyup', keyUpEvent);

        // 페이지 언마운트 시 리스너 해제
        return () => {
            window.removeEventListener('keyup', keyUpEvent);
        }
    }, []);

    /*
    * 뒤로가기 감지 시, 모달창을 닫는다.
    * 이때, history.pushState 를 통해 모달창 관련 State 가 등록이 되어있다고 가정한다.
    * State 가 등록이 되어있기 때문에 현재 페이지에서 이전 페이지로 이동이 되지 않는다.
    * */
    useEffect(() => {
        window.addEventListener("popstate", () => {
            try {
                document.getElementById(modalId).close();
            } catch (_) {}
        });
    }, []);

    // 기존에 등록된 State 를 pop 하고 모달창을 닫는다.
    const closeModal = () => {
        // eslint-disable-next-line no-restricted-globals
        history.back();
        document.getElementById(modalId).close();
    }

    return <dialog id={modalId} className="modal">
        <div className={'w-full flex flex-col items-center'}>
            <div className="modal-box p-0 max-w-[600px] scrollbar-hide z-[60]">
                <div className={'sticky top-0 bg-white z-[60]'}>
                    {
                        showClose &&
                        <div className={'flex justify-end'}>
                            <img
                                className={'absolute mt-[8px] mr-[8px] cursor-pointer w-[26px] h-[26px] md:w-[48px] md:h-[48px] z-50'}
                                src={closeCircled}
                                onClick={closeModal}/>
                        </div>
                    }
                    {
                        showCarousel && carouselImages.length > 0 &&
                        <div className="carousel w-full h-[200px] md:h-[300px]">
                            {
                                carouselImages.map((element, index) => {
                                    return (
                                        <div id={`slide${index}`} className="carousel-item relative w-full">
                                            <img
                                                src={element}
                                                className={clsx("w-full", [boxFit === 'cover' ? 'object-cover' : 'object-contain'])}
                                                // className={ clsx("w-full absolute -top-16") }
                                            />
                                            <div
                                                className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
                                                {
                                                    index !== 0 ?
                                                        <a href={`#slide${index - 1}`}>
                                                            <img
                                                                className={'w-[30px] h-[30px] md:w-[40px] md:h-[40px]'}
                                                                src={arrowLeftCircled}
                                                                alt={'좌측 이동'}/>
                                                        </a> : <div/>
                                                }
                                                {
                                                    index !== (carouselImages.length - 1) &&
                                                    <a href={`#slide${index + 1}`}>
                                                        <img className={'w-[30px] h-[30px] md:w-[40px] md:h-[40px]'}
                                                             src={arrowRightCircled}
                                                             alt={'우측 이동'}/>
                                                    </a>
                                                }
                                            </div>
                                            <div
                                                className={'bg-grey-opacity-0.76 px-[16px] py-[8px] text-xs rounded-lg text-white absolute right-[8px] md:right-[40px] bottom-[8px] md:bottom-[20px]'}>
                                                {`${index + 1} / ${carouselImages.length}`}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                </div>
                {children}
            </div>
        </div>
        <form method="dialog" className="modal-backdrop w-full h-full absolute z-50" onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            history.back();
        }}>
            <button className={'w-full h-full cursor-default'}>close</button>
        </form>
    </dialog>
}

export default Dialog;