import {
    goeun1, goeun2, goeun3, goeun4, goeun5, goeun6,
    pyeonan1,
    pyeonan2,
    pyeonan3,
    pyeonan4,
    pyeonan5,
    pyeonan6,
    pyeonan7,
    pyeonan8,
    pyeonan9
} from "./images";

export const cooperation = [
    {
        title: '편안한움직임 건강관리&필라테스',
        content: '척추측만특화운동 3D메소드교육센터로써 바디워크스트레칭과 체형교정필라테스를 전문으로 하는 1:1 맞춤케어센터입니다.',
        owner: '안상용',
        address: '경기도 분당구 정자동 정자일로 248 파크뷰휘트니스 6층',
        tel: '031-783-3025',
        holiday: null,
        businessHours: null,
        subway: null,
        blog: null,
        instagram: 'https://www.instagram.com/opparkview',
        home: 'https://pyeonanhan.modoo.at',
        images: [
            'img/pyonanhan_umgicim/1.jpg',
            'img/pyonanhan_umgicim/2.jpg',
            'img/pyonanhan_umgicim/3.jpg',
            'img/pyonanhan_umgicim/4.jpg',
            'img/pyonanhan_umgicim/5.jpg',
            'img/pyonanhan_umgicim/6.jpg',
            'img/pyonanhan_umgicim/7.jpg',
            'img/pyonanhan_umgicim/8.jpg',
            'img/pyonanhan_umgicim/9.jpg',
        ],
    },
    {
        title: '고은경희한의원',
        content: '예쁨을 넘어선 고은얼굴, 고은 몸을 만들어드리는 고은경희한의원입니다. 소아성장, 면역력관리, 알레르기비염, 교통사고 후유증, 비만, 척추 교정치료를 전문으로 합니다.',
        owner: '이한별',
        address: '서울 구로구 디지털로34길 43 코오롱싸이언스밸리1차 205호 고은경희한의원',
        tel: null,
        holiday: null,
        businessHours: null,
        subway: null,
        blog: null,
        instagram: null,
        home: 'https://koeunkh.modoo.at/',
        images: [
            'img/goen_keonghee_hospital/1.png',
            'img/goen_keonghee_hospital/2.png',
            'img/goen_keonghee_hospital/3.jpg',
            'img/goen_keonghee_hospital/4.jpg',
            'img/goen_keonghee_hospital/5.jpg',
            'img/goen_keonghee_hospital/6.jpg',
        ],
    },
    {
        title: '보나트페이스',
        content: '미국에서 카이로프랙틱을 전공한 안준용박사는 척추를 포함한 신체의 균형을 위해 노력해서 성공적인 결과를 만들었으며, 보나트페이스에서는  오랜 임상경험을 얼굴에 집중하여 안면의 구조적 변화를 오직 손으로 변화시켜 얼굴을  균형있고 더욱 아름답게 만드는 서비스를 제공하고 있습니다.\n현재는 인기배우와 Kpop 스타들의 비수술 안면윤곽관리로 소문나 있습니다.',
        owner: '안준용',
        address: '서울특별시 강남구 선릉로 132길 13-3, 2층',
        tel: '02-543-4999',
        holiday: null,
        businessHours: null,
        subway: null,
        blog: null,
        instagram: null,
        home: 'https://www.bonartface.co.kr',
        images: [
            'img/bonat_face/1.jpeg',
        ],
    },
    {
        title: '샤샤정 필라테스',
        content: '대표이사 샤샤정은 국내 퍼스널 트레이닝 1세대로서 California Fitness Asis 최고 매출을 달성하는 등 말 그대로 퍼스널 트레이너의 성공 교과서입니다. \n' +
            '유명스타들의 바디를 오랜 시간 동안 책임지며 스타 트레이너로 최고의 자리를 지키고 있습니다. 샤샤정의 성공 노하우 결정체가 바로 샤샤필라테스, (주)샤샤정 헌드레드 입니다.',
        owner: '샤샤정',
        address: '서울특별시 강남구 압구정로30길 53 성지빌딩 3층',
        tel: '02-517-4405',
        holiday: null,
        businessHours: null,
        subway: '3호선 압구정역 3번출구로 나와 직진 > 농협 골목으로 좌회전 > 아미띠에카페 3층 > 샤샤필라테스',
        blog: null,
        instagram: null,
        home: 'https://shashapilates1.modoo.at/',
        images: [
            'img/shashajeong_pilates/1.jpeg',
            'img/shashajeong_pilates/2.jpeg',
            'img/shashajeong_pilates/3.jpeg',
            'img/shashajeong_pilates/4.jpeg',
            'img/shashajeong_pilates/5.jpeg',
            'img/shashajeong_pilates/6.jpeg',
        ],
    },
];