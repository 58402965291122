export function paginate(collection, index, size) {
  index = Math.max(0, index - 1);
  size = Math.max(1, size);
  const start = index * size;
  const end = (index + 1) * size;
  return collection.slice(start, end);
}

export const numberCheck = (callback) => {
  return (e) => {
    const { value } = e.target;
    const onlyNumber = value.replace(/[^0-9]/g, '');
    callback(onlyNumber);
  }
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const clickImg = (imgsrc) =>{
  // alert(imgsrc);
  let imageWin = new Image();
  imageWin.src = imgsrc;
  imageWin = window.open("", "profile_popup", "width=" + imageWin.width + "px, height=" + imageWin.height + "px");
  imageWin.document.write("<html><body style='margin:0'>");
  imageWin.document.write("<a href=javascript:window.close()><img src='" + imgsrc + "' border=0></a>");
  imageWin.document.write("</body><html>");
  imageWin.document.title = 'image';
}
