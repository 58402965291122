import React, {useEffect, useState} from "react";
import { FC, ReactNode } from "react";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Header from "./Header";

const DefaultLayout = ({ children }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrolled, setScrolled] = useState(false);

    // Scroll 위치를 감지
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    };

    useEffect(() => {
        window.addEventListener("scroll", updateScroll);
        return () => {
            window.removeEventListener("scroll", updateScroll);
        };
    }, []);

    // scroll 위치가 100이하라면 투명한 배경색을 지정하고, 아니면 흰색을 지정한다.
    useEffect(() => {
        if (scrollPosition < 50) {
            setScrolled(false);
        } else {
            setScrolled(true);
        }
    }, [scrollPosition]);

    return (
        <div>
            <Sidebar/>
            <Header scrolled={scrolled}/>
            { children }
            <Footer />
        </div>
    );
}

export default DefaultLayout;