import DefaultLayout from "../../components/DefaultLayout";
import {
    army,
    arrowLeftCircled,
    arrowRightCircled,
    cfb,
    downloadBrouchur,
    draju,
    gb,
    ge, kbs,
    knsu,
    main4,
    main6, mtn, service1, service2, service3, service4, service5, service6, service7, service8, spotv
} from "../../images";
import {useCallback, useState} from "react";
import ImageViewer from 'react-simple-image-viewer';
import BannerContainerM from "../../components/container/BannerContainerM";

/* 관리되는 업체 컨테이너 */
const ServiceContainer = ({title, image}) => {
    return <div
        // className={'pl-[20px] pr-[22.5px] py-[20px] bg-[rgb(248_248_248)] rounded-xl flex flex-row items-center gap-x-[16px]'}
        className={'md:h-auto h-[74px] px-[10px] md:pl-[20px] md:pr-[22.5px] md:py-[20px] bg-white border rounded-xl flex flex-row justify-center items-center gap-x-[16px]'}
    >
        {/*<img className={'w-[64px] h-[64px]'} src={image} alt={'이미지'}/>*/}
        <div className={'h-16 flex justify-center items-center'}>
            <img className={ 'md:h-auto' } src={image} alt={'이미지'}/>
        </div>
        {/*<span className={'text-[22px] font-semibold'}>{title}</span>*/}
    </div>
}

/* 텍스트필드 with 라벨 */
const ServiceTextFieldWithLabel = ({label, placeholder}) => {
    return <div className={'flex flex-col'}>
        <span className={'text-16px md:text-[30px] font-semibold'}>{label}</span>
        <input
            className={'bg-[rgb(248_248_248)] py-[8px] md:py-[20px] border-b border-[rgb(226_226_226)] text-14px md:text-[20px] px-1 focus:outline-0'}
            type={'text'} placeholder={placeholder}/>
    </div>
}

const Service = () => {
    const serviceImageList = [service1, service2, service3, service4, service5, service6];
    const coopsImageList = [spotv, army, knsu, cfb, draju, ge, gb, kbs, mtn];

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const carouselImages = ['https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg', 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg', 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'];

    const url = 'https://medilancekorea.com/service.pdf';

    const [isDownloading, setIsDownloading] = useState(false);

    const onClickImgLink = useCallback((srcUrl, name) => {
        setIsDownloading(true);
        fetch(srcUrl, { method: 'GET' }).then((res) => res.blob()).then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            setTimeout((_) => {
                window.URL.revokeObjectURL(url);
            }, 1000);
            a.remove();
        }).catch((err) => {
            console.error('err', err);
        }).finally(() => {
            setIsDownloading(false);
        });
    }, []);

    return (
        <DefaultLayout>
            {isViewerOpen && (
                <ImageViewer
                    src={serviceImageList}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        zIndex: 100
                    }}
                />
            )}

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <img className={'hidden md:block'} src={main6} style={{width: '100%', minWidth: '900px'}}/>
                <BannerContainerM title={'찾아가는 서비스'} content={'이제 헬스케어도 지금 계신 곳에서 빠르고 편하게 받아보세요. 메딜런스의 찾아가는 헬스케어 서비스를 우리 회사, 학교, 학원, 유치원에서 받아보세요.'}/>
            </div>
            <div className={'md:py-[120px] py-[52px] px-[20px] md:px-16 xl:px-[300px] flex justify-center'}>
                <div className={'flex flex-col'}>
                    <div className={'flex flex-wrap justify-between'}>
                        <span className={'text-20px md:text-[40px] font-bold'}>메딜런스 서비스를 우리 회사, 학교, 학원, 유치원에서 받아보세요.</span>
                        {
                            !isDownloading ?
                                <img className={'h-[50px] md:h-[70px] cursor-pointer'} src={downloadBrouchur} alt={'브로셔 다운로드'}
                                     onClick={() => onClickImgLink(url, '메딜런스 찾아가는 서비스 (Brochure).pdf')}/>
                                : <span className="loading loading-spinner loading-lg"></span>
                        }
                    </div>
                    <div className={'h-[30px]'}/>
                    <iframe title={'intro'} className={'rounded-lg h-[200px] md:h-[500px]'}
                            src="https://www.youtube.com/embed/8wR5tvQijGo?autoplay=1&mute=1"
                            frameBorder="0"
                            gesture="media" allow="encrypted-media" allowFullScreen></iframe>
                    <div className={'h-[30px]'}/>
                    <div className={'overflow-scroll flex gap-x-[20px]'}>
                        {
                            serviceImageList.map((element, index) => {
                                return (
                                    <img className={'w-[100px] h-[100px] md:w-[180px] md:h-[180px] rounded-lg hover:scale-105 object-cover cursor-pointer transition duration-150'} src={element}
                                         alt={'이미지'} onClick={ () => openImageViewer(index) }/>
                                );
                            })
                        }
                    </div>
                    {/*<div className="carousel w-full h-[300px] relative">*/}
                    {/*    {*/}
                    {/*        carouselImages.map((element, index) => {*/}
                    {/*            return (*/}
                    {/*                <div id={`slide${index}`} className="carousel-item relative w-full">*/}
                    {/*                    <img*/}
                    {/*                        src={element}*/}
                    {/*                        className="w-full object-cover"/>*/}
                    {/*                    <div*/}
                    {/*                        className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">*/}
                    {/*                        {*/}
                    {/*                            index !== 0 ?*/}
                    {/*                                <a href={`#slide${index - 1}`}>*/}
                    {/*                                    <img className={'w-[40px] h-[40px]'} src={arrowLeftCircled}*/}
                    {/*                                         alt={'좌측 이동'}/>*/}
                    {/*                                </a> : <div/>*/}
                    {/*                        }*/}
                    {/*                        {*/}
                    {/*                            index !== (carouselImages.length - 1) &&*/}
                    {/*                            <a href={`#slide${index + 1}`}>*/}
                    {/*                                <img className={'w-[40px] h-[40px]'} src={arrowRightCircled}*/}
                    {/*                                     alt={'우측 이동'}/>*/}
                    {/*                            </a>*/}
                    {/*                        }*/}
                    {/*                    </div>*/}
                    {/*                    <div*/}
                    {/*                        className={'bg-grey-opacity-0.76 px-[16px] py-[8px] text-xs rounded-lg text-white absolute right-[40px] bottom-[20px]'}>*/}
                    {/*                        {`${index + 1} / ${carouselImages.length}`}*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            );*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <div className={'h-[64px] md:h-[120px]'}/>
                    <span className={'text-20px md:text-[40px] font-bold'}>협력 단체</span>
                    <div className={'h-[16px] md:h-[30px]'}/>
                    <div>
                        {/* 데스크탑 적응형 */}
                        <div
                            className={'hidden md:flex flex-col lg:grid lg:grid-cols-3 2xl:grid-cols-4 gap-x-[18px] gap-y-[20px]'}>
                            {
                                coopsImageList.map(element => <ServiceContainer image={element}/>)
                            }
                        </div>
                        {/* 모바일 적응형 */}
                        <div
                            className={'md:hidden grid grid-cols-4 gap-[8px]'}>
                            {
                                coopsImageList.map(element => <ServiceContainer image={element}/>)
                            }
                        </div>
                    </div>
                    <div className={'h-[80px] md:h-[120px]'}/>
                    <span className={'text-20px md:text-[40px] font-bold'}>메딜런스 찾아가는 서비스를 신청하고 관리를 받아보세요.</span>
                    <div className={'h-[16px] md:h-[30px]'}/>
                    <div
                        className={'w-full rounded-xl bg-[rgb(248_248_248)] pt-[24px] pb-[20px] px-[20px] md:pt-[40px] md:pb-[50px] md:px-[50px] flex flex-col gap-y-[28px] md:gap-y-[82px]'}>
                        <ServiceTextFieldWithLabel label={'회사명'} placeholder={'회사명을 입력해주세요.'}/>
                        <ServiceTextFieldWithLabel label={'연락처'} placeholder={'연락처를 입력해주세요.'}/>
                        <ServiceTextFieldWithLabel label={'단체명'} placeholder={'단체명을 입력해주세요.'}/>
                        <ServiceTextFieldWithLabel label={'이메일'} placeholder={'이메일을 입력해주세요.'}/>
                        {/*  개인정보 활용 동의  */}
                        <div className={'flex flex-col md:gap-y-[26px]'}>
                            <div className={'flex flex-row justify-between items-center'}>
                                <span className={'text-16px md:text-[30px] font-semibold'}>개인정보 활용 동의</span>
                                <div className={'flex flex-row gap-x-[10px] items-center'}>
                                    <span className={'text-14px md:text-[20px] font-medium'}>동의</span>
                                    <input type="checkbox" defaultChecked className="checkbox checkbox-sm md:checkbox-lg"/>
                                </div>
                            </div>
                            <span className={'text-14px md:text-[20px] font-light whitespace-pre-wrap'}>
                                {`
회사는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여,  적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다.
이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고,  이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다  

▪개인정보의 처리목적   회사는 다음의 목적을 위하여 개인정보를 처리합니다.  처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며,  이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.   

1.  회원 가입 및 관리   회원 가입의사 확인,  회원제 서비스 제공에 따른 본인 식별·인증,  회원자격 유지·관리,  서비스 부정이용 방지,  만 14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인,  각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
2.  재화 또는 서비스 제공   물품배송,  서비스 제공,  계약서·청구서 발송,  콘텐츠 제공,  맞춤서비스 제공,  본인인증,  연령인증, 요금결제·정산,  채권추심으로 개인정보를 처리합니다.
                                `}
                            </span>
                        </div>
                    </div>
                    <div className={'h-[24px] md:h-[60px]'}/>
                    <div className={'flex justify-center'}>
                        <button type={'button'}
                                className={'bg-[rgb(30_33_38)] text-16px md:text-[24px] px-[54px] md:w-auto w-full md:h-auto h-[52px] md:py-[26px] md:font-normal font-semibold text-white rounded-xl hover:bg-gray-700 transition duration-150 select-none'}
                                onClick={() => {
                                    alert('기능 - 신청하기');
                                }}>
                            신청하기
                        </button>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
}

export default Service;