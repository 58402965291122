import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import {RecoilRoot} from "recoil";

import 'output.css';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient()

ReactDOM.render(
    <>
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <Routes />
            </QueryClientProvider>
        </RecoilRoot>
    </>,
    document.getElementById('root')
);
